.app,
.content {
    height: 100Vh;
    width: 100%;
    overflow-y: scroll;
}

/* .content {
    background-color: #000000;
} */

.app {
    overflow: hidden;
    display: flex;
}

.upload_image {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    /* border: 2px red solid; */
}

.pointer {
    cursor: pointer;
}